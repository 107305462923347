<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-8">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">{{$t('title.orders')}}</template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-4 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-3">
                                        <!-- Using components -->
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button @click="handleReexportLoadAddClick" variant="success" size="sm"
                                                          :title="$t('button.title.createReexportLoad')"
                                                          :disabled="selectedReexportOrderLength <= 0"
                                                          v-if="$global.hasPermission('reexportloadsownview')" v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> <strong>R </strong>
                                                    <b-badge variant="dark">{{selectedReexportOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button @click="handlePriority" variant="info" size="sm"
                                                          :title="$t('button.title.priority')"
                                                          :disabled="selectedOrderLength <= 0"
                                                          v-if="$global.hasPermission('ordersupdate')" v-b-tooltip.hover>
                                                    <i class="fe fe-plus"></i> {{$t('button.priority')}}
                                                    <b-badge variant="dark">{{selectedOrderLength}}</b-badge>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersownview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetFilterClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-3"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div><!-- /.card-header -->
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 selectable
                                 :sort-by.sync="sortField"
                                 ref="orderTable"
                                 @row-clicked="handleRowClick"
                                 @sort-changed="handleSortChange">
                            <template v-slot:cell(order_id)="{ detailsShowing, item, index, rowSelected}">
                                <div class="position-relative">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                    </template>
                                    <a @click="toggleDetails(item)" class="d-inline in-center">
                                        <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                    </a>
                                    {{ (item.order_id ? item.order_id : '') }}
                                </div>
                            </template>
                            <template v-slot:cell(status)="record">
                                {{ record.item._status }}
                            </template>
                            <template v-slot:cell(load_status)="{item}">
                                <div><ol-status :status="item.load_status"></ol-status></div>
                                <tr-status v-if="item.tr_status > 0" :status="item.tr_status" :type="item.tr_status_type"></tr-status>
                                <template v-if="item.load_status == '9'"><br>
                                    <b-badge v-if="item._additional_services > 0 && item.damage > 0" variant="info">{{$t('title.serviceOrders')}} & {{$t('title.damaged')}}</b-badge>
                                    <b-badge v-if="item._additional_services > 0 && !item.damage > 0" variant="info">{{$t('title.serviceOrders')}}</b-badge>
                                    <b-badge v-if="!item._additional_services > 0 && item.damage > 0" variant="info">{{$t('title.damaged')}}</b-badge>
                                    <b-badge v-if="!item._additional_services > 0 && !item.damage > 0" variant="info">{{ _.find(item.load_status_Histories, {status: 9}) ? _.find(item.load_status_Histories, {status: 9}).comments : $t('title.other')}}</b-badge>
                                </template>
                                <template v-if="item.client_affected"><br>
                                    <b-badge v-if="item.client_affected_priority === 1" variant="danger" :title="$t('title.clientAffected') + ' | ' + $t('title.high') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}} </b-badge>
                                    <b-badge v-if="item.client_affected_priority === 2" variant="warning" :title="$t('title.clientAffected') + ' | ' + $t('title.medium') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                    <b-badge v-if="item.client_affected_priority === 3" variant="secondary" :title="$t('title.clientAffected') + ' | ' + $t('title.low') + (item.client_affected_description ? ' | ' + item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                </template>
                                <template v-if="item.load_status == 8 && _.isEmpty(item.client_tr_at)"><br>
                                    <b-badge v-if="item.client_tr_at && _.isEmpty(item.client_tr_accept_at)" variant="dark">{{$t('title.deliveryRequest')}}</b-badge>
                                    <b-badge v-if="!_.isEmpty(item.client_tr_accept_at)" variant="dark">{{$t('title.readyToBeLoaded')}}</b-badge>
                                </template>
                            </template>
                            <template v-slot:cell(brand_id)="record">
                                {{ (record.item.brand ? record.item.brand.title : '') }}
                            </template>
                            <template v-slot:cell(model_id)="record">
                                {{ (record.item.model ? record.item.model.title : '') }}
                            </template>
                            <template v-slot:cell(client_tr_at)="record">
                                {{ (record.item.client_tr_at ? $global.utcDateToLocalDate(record.item.client_tr_at) : 'N/A' ) }}
                            </template>
                            <template v-slot:cell(from_location_id)="{item}">
                                <span v-if="item.from_location">
                                    <span><img :src="'/resources/images/flags/' + (_.find(dropdowns.countries, {id: item.from_location.country}) ? _.find(dropdowns.countries, {id: item.from_location.country}).sort_name : '') + '.png'" class="mr-2"></span>
                                    <span v-if="item.from_location.city" :title="item.from_location.street + ', ' +item.from_location.street_no + ', ' + item.from_location.city + ', ' + item.from_location.zip + ', ' + item.from_location.country" v-b-tooltip.hover>{{item.from_location.city}} </span>
                                </span>
                            </template>
                            <template v-slot:cell(to_location_id)="{item}">
                                <span v-if="item.to_location">
                                    <span><img :src="'/resources/images/flags/' + (_.find(dropdowns.countries, {id: item.to_location.country}) ? _.find(dropdowns.countries, {id: item.to_location.country}).sort_name : '') + '.png'" class="mr-2"></span>
                                    <span v-if="item.to_location.city" :title="item.to_location.street + ', ' + item.to_location.street_no + ', ' + item.to_location.city + ', ' + item.to_location.zip + ', ' + item.to_location.country" v-b-tooltip.hover>{{item.to_location.city}} </span>
                                </span>
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.importerNumber')}}</strong>: {{item.invoice_number_importer}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.invoiceDateImporter')}}</strong>: {{item.invoice_date_importer}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comment')}}</strong>: {{item.comments}}</b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                            <template v-slot:cell(logistic_type)="record">
                                <span class="text-capitalize">
                                    <span
                                        v-if="record.item.tr_status_type == 'outbound'">{{$t('title.outbound')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'reexport'">{{$t('title.reexport')}}</span>
                                    <span
                                        v-if="record.item.tr_status_type == 'dd'">D2D</span>
                                    <span v-if="record.item.tr_status_type == 'cc'">CC</span>

                                </span>
                            </template>
                            <template v-slot:cell(action)="record">
                                <a @click="handleAdditionalServiceClick(record.item)"
                                   v-if="record.item.load_status == 8 || record.item.load_status == 9"
                                   :title="$t('button.title.manageAdditionalServices')"
                                   class="ml-1"
                                   v-b-tooltip.hover>
                                    <i class="fe fe-file-plus"></i>
                                </a>
<!--                                <a @click="handleEditClick(record.item)"-->
<!--                                   v-if="(!record.item.transport_request) && (record.item.load_status == 8)"-->
<!--                                   :title="$t('button.title.createTransportRequest')"-->
<!--                                   class="ml-1"-->
<!--                                   v-b-tooltip.hover>-->
<!--                                    <i class="fe fe-truck"></i>-->
<!--                                </a>-->
                                <a-popconfirm title="Are you sure？" @confirm="handleResetTrClick(record.item)"
                                              v-if="$global.hasPermission('ordersownview') && record.item.tr_status > 0 && (record.item.load_status == 8 || record.item.load_status == 9)">
                                    <i slot="icon" class="fe fe-trash"></i>
                                    <a class=" ml-1"
                                       :title="$t('button.title.deleteTransportRequest')"
                                       v-b-tooltip.hover>
                                        <i class="fe fe-unlock"></i>
                                    </a>
                                </a-popconfirm>
                                <a @click="setOperation('detail', record.item.id)"
                                   class="ml-1"
                                   :title="$t('button.title.detailItem')"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </template>
                        </b-table><!-- /.b-table -->

                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                        v-model="pagination.current"
                                        :total-rows="pagination.total"
                                        :per-page="pagination.perPage"
                                        :first-text="$t('paginations.first')"
                                        :prev-text="$t('paginations.prev')"
                                        :next-text="$t('paginations.next')"
                                        :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->
                    <div class="orders-operation">
                        <a-drawer
                            placement="right"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleOperationClose"
                            :visible="formFields.visible"
                            :zIndex="10"
                            :title="$t('title.createTransportRequest')"
                        >
                            <form @submit.prevent="handleSubmit" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.type')+' *'"
                                            label-for="type"
                                            :invalid-feedback="formErrors.first('type')">
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.transportTypes"
                                                placeholder=""
                                                v-model="formFields.type"
                                                :class="[{'invalid is-invalid': (formErrors.has('type'))}]"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col cols="12">
                                        <b-form-group
                                            :label="$t('input.comment')"
                                            label-for="comment"
                                            :invalid-feedback="formErrors.first('comments')">
                                            <b-textarea
                                                id="comment"
                                                v-model="formFields.comments"
                                                type="text"
                                                :state="((formErrors.has('comments') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-textarea>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <div class="drawer-footer">
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="handleOperationClose()"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </div>
                                </b-row><!--/b-row-->
                            </form><!--/form-->
                        </a-drawer>

                        <a-drawer
                            placement="right"
                            :width="'86%'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="handleAdditionalServiceOperationClose"
                            :visible="additionalServiceFormFields.visible"
                            :zIndex="10"
                            :title="$t('title.manageAdditionalServices')"
                        >
                            <form @submit.prevent="handleAdditionalServiceSubmit" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-row>
                                            <b-col sm="3">
                                                <div class="form-group mb-1">
                                                    <label class="d-block">{{$t('input.additionalService')}} *</label>
                                                    <treeselect
                                                        :multiple="false"
                                                        :options="dropdowns.suppliersAdditionalServices"
                                                        placeholder=""
                                                        :disabled="!(!(additional_service.is_old && additional_service.is_old > 10))"
                                                        v-model="additional_service.additional_service_id"
                                                        :class="[{'invalid is-invalid': (formErrors.has('additional_service_id'))}]"
                                                    />
                                                    <div class="invalid-feedback">{{formErrors.first('additional_service_id')}}</div>
                                                </div><!-- /.form-group -->
                                            </b-col>
                                            <b-col sm="7">
                                                <b-form-group
                                                    :label="$t('input.description')"
                                                    label-for="description"
                                                    :invalid-feedback="formErrors.first('description')"
                                                    >
                                                    <b-form-input
                                                        id="description"
                                                        v-model="additional_service.description"
                                                        type="text"
                                                        :state="((formErrors.has('description') ? false : null))"
                                                    ></b-form-input>
                                                </b-form-group>
                                            </b-col>
                                            <b-col sm="1">
                                                <div class="form-group ">
                                                    <label class="d-block"><pre> </pre></label>
                                                    <b-button variant="info" type="button" class="btn-block" size="sm" @click="handleAddUpdateAdditionalLocationClick">
                                                        <i class="fa fa-plus"></i>
                                                    </b-button><!--/b-button-->
                                                </div><!-- /.form-group -->
                                            </b-col>
                                            <b-col sm="1">
                                                <div class="form-group ">
                                                    <label class="d-block"><pre> </pre></label>
                                                    <b-button variant="warning" type="button" class="btn-block" size="sm" @click="resetAdditionalLocation">
                                                        <i class="fa fa-refresh"></i>
                                                    </b-button><!--/b-button-->
                                                </div><!-- /.form-group -->
                                            </b-col>
                                            <b-col sm="12">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th width="10">#</th>
                                                            <th width="250">{{$t('column.additionalService')}}</th>
                                                            <th>{{$t('column.description')}}</th>
                                                            <th>{{$t('column.compoundNote')}}</th>
                                                            <th width="100">{{$t('column.status')}}</th>
                                                            <th width="100">{{$t('column.action')}}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(ad, index) in additional_services">
                                                            <td>{{index + 1}}</td>
                                                            <td>
                                                                <span v-if="dropdowns.suppliersAdditionalServices.length > 0">
                                                                    {{_.find(dropdowns.suppliersAdditionalServices, {id: ad.additional_service_id}).label}}
                                                                </span>
                                                            </td>
                                                            <td>{{ad.description}}</td>
                                                            <td>{{ad.compound_notes}}</td>
                                                            <td>
                                                                <span v-if="ad.is_rejected">
                                                                    {{$t('title.rejected')}}
                                                                </span>
                                                                <span v-else>
                                                                    {{ad.marked_done_at ? $t('title.done') : $t('title.pending') }}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <template v-if="!ad.is_rejected">
                                                                    <a @click="handleEditAdditionalLocationClick(ad.token)"
                                                                       :title="$t('button.title.editItem')"
                                                                       v-if="!ad.marked_done_at"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-edit"></i>
                                                                    </a>
                                                                    <a-popconfirm title="Are you sure？" @confirm="handleDeleteAdditionalLocationClick(ad.token)" v-if="ad.is_old <= 10">
                                                                        <i slot="icon" class="fe fe-trash"></i>
                                                                        <a class=" ml-1"
                                                                           :title="$t('button.title.deleteItem')"
                                                                           v-if="!ad.marked_done_at"
                                                                           v-b-tooltip.hover>
                                                                            <i class="fe fe-trash"></i>
                                                                        </a>
                                                                    </a-popconfirm>
                                                                </template>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                    <tfoot v-show="additional_services.length <= 0">
                                                        <tr>
                                                            <th colspan="6">{{$t('title.noDataAvailable')}}</th>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    <div class="drawer-footer">
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="handleAdditionalServiceOperationClose()"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </div>
                                </b-row><!--/b-row-->
                            </form><!--/form-->
                        </a-drawer>
                    </div><!--/.orders-operation-->

                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                                :label="$t('input.brands')">
                                            <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.brands"
                                                    placeholder=""
                                                    v-model="filters.brands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                                :label="$t('input.models')">
                                            <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.models"
                                                    placeholder=""
                                                    v-model="filters.models"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                                :label="$t('input.dealers')">
                                            <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.dealers"
                                                    placeholder=""
                                                    v-model="filters.dealers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                                :label="$t('input.fromLocations')">
                                            <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.locations"
                                                    placeholder=""
                                                    v-model="filters.fromLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                                :label="$t('input.toLocations')">
                                            <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.locations"
                                                    placeholder=""
                                                    v-model="filters.toLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                                :label="$t('input.markets')">
                                            <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.countries"
                                                    placeholder=""
                                                    v-model="filters.order_countries"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.supplierCompound')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.supplierCompounds"
                                                placeholder=""
                                                v-model="filters.suppliers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                                :label="$t('input.status')">
                                            <treeselect
                                                    :multiple="true"
                                                    :options="dropdowns.status"
                                                    placeholder=""
                                                    v-model="filters.status"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('title.additionalInformation')">
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.statusAdditionalInfo"
                                                placeholder=""
                                                v-model="filters.status_additional_info"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div><!-- /.card-body-->
            </div><!-- /.card -->
            <div v-if="operation === 'detail'">
                <detail :handle-close-operation="handleOperationClose"></detail>
            </div>

            <!-- # Start Priority Operation -->
            <div class="priority-operation">
                <a-drawer
                    placement="right"
                    :width="'450px'"
                    :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                    :closable="false"
                    @close="handlePriorityFormOperationClose(true)"
                    :visible="priorityFormVisible"
                    :zIndex="10"
                    :title="$t('title.addPriority')"
                >
                    <form @submit.prevent="handlePrioritySubmit(true)" autocomplete="off">
                        <b-row>
                            <b-col cols="12" v-show="global.pendingRequests > 0">
                                <a-skeleton active :paragraph="{ rows: 5 }"/>
                            </b-col>
                            <b-col cols="12" v-show="global.pendingRequests <= 0">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.priority')+' *'"
                                            label-for="client_affected_priority"
                                            :invalid-feedback="formErrors.first('client_affected_priority')">
                                            <treeselect
                                                :multiple="false"
                                                :options="dropdowns.priorities"
                                                placeholder=""
                                                v-model="priorityFormState.client_affected_priority"
                                                :class="[{'invalid is-invalid': (formErrors.has('client_affected_priority'))}]"
                                            />
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.comment')"
                                            label-for="client_affected_description"
                                            :invalid-feedback="formErrors.first('client_affected_description')"
                                        >
                                            <b-form-textarea
                                                id="client_affected_description"
                                                v-model="priorityFormState.client_affected_description"
                                                :state="((formErrors.has('client_affected_description') ? false : null))"
                                                @focus="$event.target.select()"
                                            ></b-form-textarea>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row><!--/b-row-->
                            </b-col><!--/b-col-->
                            <div class="drawer-footer">
                                <b-row>
                                    <b-col sm="6" class="text-left">
                                        <b-form-group
                                            :invalid-feedback="formErrors.first('client_affected')"
                                        >
                                            <b-form-checkbox v-model="priorityFormState.client_affected" name="client_affected">
                                                {{$t('input.clientAffected')}}
                                            </b-form-checkbox>
                                        </b-form-group>
                                    </b-col>
                                    <b-col sm="6">
                                        <b-button
                                            size="sm"
                                            type="submit"
                                            variant="primary"
                                            :disabled="global.pendingRequests > 0"
                                            v-b-tooltip.hover :title="$t('button.title.save')"
                                        >
                                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                                         size="12px"
                                                         v-if="global.pendingRequests > 0"></clip-loader>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('button.save')}}
                                        </b-button>
                                        <b-button variant="warning" class="ml-3"
                                                  size="sm" @click="handlePriorityFormOperationClose(true)"
                                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </div>
                        </b-row><!--/b-row-->
                    </form><!--/form-->
                </a-drawer>
            </div><!--/.priority-operation-->

            <!-- # Start ReExport Load -->
            <a-drawer
                placement="right"
                :width="'86%'"
                :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                :closable="false"
                @close="handleReexportLoadFormOperationClose"
                :visible="reexportLoadFormVisible"
                :zIndex="10"
                :title="$t('title.createReexportLoad')"
            >

                <form @submit.prevent="() => handleReexportLoadSubmit('reexport/loads/client/create')" autocomplete="off">
                    <b-row v-if="groupedReexportOrders.length > 0">
                        <b-col sm="12" v-for="(group, index) in groupedReexportOrders" :key="index">
                            <b-row>
                                <b-col sm="12" lg="2" md="2" class=" mb-sm-3">
                                    <h4 class="d-sm-inline">
                                    <span class="bg-gray-6 ml-2 rounded text-white p-1">
                                        {{$t('title.load')}}#<strong>{{index + 1}}</strong>
                                    </span>
                                    </h4>
                                    <h4 class="mt-4 ml-2 d-sm-inline">
                                    <span class="bg-light rounded p-1">
                                        <small>{{$t('title.orders')}}-{{group.items.length}}</small>
                                    </span>
                                    </h4>
                                </b-col>
                                <b-col sm="12" lg="3" md="3" class="ml-sm-1 mt-sm-2">
                                    <dl>
                                        <dt>{{$t('title.fromLocation')}}:</dt>
                                        <dd>
                                            <print-ad :item="group.from_location"></print-ad>
                                        </dd><!--/fromlocation-->
                                        <dt>{{$t('title.supplierCompound')}}:</dt>
                                        <dd>{{group.supplier_compound.name}}</dd><!--/supplier_compound-->

                                        <dt>VIN:</dt>
                                        <dd>
                                            <div v-for="(order, index) in group.items">
                                                {{order.vin_number}}
                                            </div>
                                        </dd>
                                    </dl>
                                </b-col>
                                <b-col sm="12" lg="2" md="2" class="ml-sm-1 mt-sm-2">
                                    <dl>
                                        <dt>{{$t('title.brand')}}:</dt>
                                        <dd>{{group.brand.title}}</dd><!--/brand-->

                                        <dt>{{$t('title.model')}}:</dt>
                                        <dd>
                                            <div v-for="(order, index) in _.uniqBy(group.items, 'model.title')">
                                                {{order.model.title}}-{{order.model.version_code}}
                                            </div>
                                        </dd><!--/model-->
                                    </dl>
                                </b-col>
                                <b-col sm="12" lg="4" md="4">
                                    <div>
                                        <small v-if="reexportLoadFormState.orders[index].errors" class="mb-2 d-block">
                                            <span class="text-danger" v-html="reexportLoadFormState.orders[index].errors"></span>
                                        </small>
                                    </div>
                                    <div class="form-group mb-2">
                                        <div class="clearfix">
                                            <span class="pull-left">
                                                <label class="d-block">{{$t('input.supplierCarrier')}} *</label>
                                            </span><!-- /.pull-left -->
                                            <span class="pull-right">
                                                <quick-supplier-form
                                                    allow-update="1"
                                                    allow-create="1"
                                                    :id="reexportLoadFormState.orders[index].supplier_carrier_id"
                                                    :after-create="(inputs) => handleAfterSupplierCreate(inputs, index)"
                                                    :after-update="(inputs) => handleAfterSupplierUpdate(inputs, index)">
                                                </quick-supplier-form>
                                            </span><!-- /.pull-right -->
                                        </div><!-- /.clearfix -->
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.supplierCarriers"
                                            placeholder=""
                                            v-model="reexportLoadFormState.orders[index].supplier_carrier_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('supplier_carrier_id'))}]"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('supplier_carrier_id')}}</div>
                                    </div><!-- /.form-group -->

                                    <div class="form-group mb-2">
                                        <div class="clearfix">
                                        <span class="pull-left">
                                            <label class="d-block">{{$t('input.toLocation')}} *</label>
                                        </span><!-- /.pull-left -->
                                            <span class="pull-right">
                                            <quick-location-form
                                                allow-update="1"
                                                allow-create="1"
                                                :id="reexportLoadFormState.orders[index].to_location_id"
                                                :after-create="(inputs) => handleAfterLocationCreate(inputs, index)"
                                                :after-update="(inputs) => handleAfterLocationUpdate(inputs, index)">
                                            </quick-location-form>
                                        </span><!-- /.pull-right -->
                                        </div><!-- /.clearfix -->
                                        <treeselect
                                            :multiple="false"
                                            :options="dropdowns.locations"
                                            placeholder=""
                                            v-model="reexportLoadFormState.orders[index].to_location_id"
                                            :class="[{'invalid is-invalid': (formErrors.has('to_location_id'))}]"
                                        />
                                        <div class="invalid-feedback">{{formErrors.first('to_location_id')}}</div>
                                    </div><!-- /.form-group -->
                                    <div class="form-group mb-2">
                                        <b-form-group
                                            :label="$t('input.price')+' *'"
                                            label-for="price"
                                            :invalid-feedback="formErrors.first('price')"
                                        >
                                            <b-form-input
                                                id="price"
                                                v-model="reexportLoadFormState.orders[index].price"
                                                type="text"
                                                :state="((formErrors.has('price') ? false : null))"
                                            ></b-form-input>
                                        </b-form-group>
                                    </div><!-- /.form-group -->
                                </b-col>
                                <b-col lg="12" md="12" sm="12" class="mt-3 mb-5">
                                    <b-card class="mb-0">
                                        <b-card-header v-b-toggle="'reexport-load-documents-'+index" class="p-0">
                                            <h4 class="mb-0">
                                            <span class="badge badge-primary">
                                                {{$t('title.attachments')}}
                                            </span>
                                                <small v-show="reexportLoadFormState.orders[index].documents.length > 0">
                                                    - {{reexportLoadFormState.orders[index].documents.length}} Item/s
                                                </small>
                                            </h4>
                                        </b-card-header><!-- /.p-0-->
                                        <b-collapse :id="`reexport-load-documents-${index}`">
                                            <div class="bg-light p-3">
                                                <b-row>
                                                    <b-col lg="4" md="4" sm="12">
                                                        <div class="form-group">
                                                            <b-form-group
                                                                :label="$t('input.title')+' *'"
                                                                label-for="title"
                                                                :invalid-feedback="formErrors.first('title')"
                                                            >
                                                                <b-form-input
                                                                    id="title"
                                                                    v-model="reexportLoadFormState.orders[index].document.title"
                                                                    type="text"
                                                                    :state="((formErrors.has('title') ? false : null))"
                                                                    @focus="$event.target.select()"
                                                                ></b-form-input>
                                                            </b-form-group>
                                                            <div class="invalid-feedback">{{formErrors.first('title')}}</div>
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="4" md="4" sm="12">
                                                        <b-form-group class="mt-4 pt-2">
                                                            <upload v-model="reexportLoadFormState.orders[index].document.document"
                                                                    :disabled="reexportLoadFormState.orders[index].document.document"
                                                                    :title="(reexportLoadFormState.orders[index].document.document ? $t('msc.uploadedFile') : $t('msc.uploadFile'))"
                                                                    css-class="mt-0 btn-sm"></upload>
                                                            <b-button :title="$t('msc.removeUpload')"
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      v-b-tooltip.hover
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      @click="() => {reexportLoadFormState.orders[index].document.document = null;}"
                                                                      :disabled="!reexportLoadFormState.orders[index].document.document"
                                                                      v-if="reexportLoadFormState.orders[index].document.document">
                                                                <i class="fa fa-close"></i>
                                                            </b-button>
                                                            <b-button :title="$t('button.download')"
                                                                      v-b-tooltip.hover
                                                                      type="button"
                                                                      variant="outline-primary"
                                                                      class="ml-2 ml-2 btn-sm"
                                                                      v-if="reexportLoadFormState.orders[index].document.document && reexportLoadFormState.orders[index].document.document.download_url"
                                                                      :disabled="!(reexportLoadFormState.orders[index].document.document && reexportLoadFormState.orders[index].document.document.download_url)"
                                                                      :href="(reexportLoadFormState.orders[index].document.document ? reexportLoadFormState.orders[index].document.document.download_url : '')"
                                                                      target="_blank">
                                                                <i class="fa fa-cloud-download"></i>
                                                            </b-button>
                                                        </b-form-group>
                                                        <div class="invalid-feedback d-block">{{formErrors.first('document')}}</div>
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block"><pre> </pre></label>
                                                            <b-button variant="info" type="button" size="sm" class="btn-block" @click="handleAddUpdateReexportLoadDocumentClick(index)">
                                                                <i class="fa fa-plus"></i>
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                    <b-col lg="2" md="2" sm="12">
                                                        <div class="form-group">
                                                            <label class="d-block"><pre> </pre></label>
                                                            <b-button variant="warning" type="button" size="sm" class="btn-block" @click="resetReexportLoadDocument(index)">
                                                                {{$t('button.reset')}}
                                                            </b-button><!--/b-button-->
                                                        </div><!-- /.form-group -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                                <b-row>
                                                    <b-col cols="12">
                                                        <table class="table table-bordered bg-white">
                                                            <thead>
                                                            <tr>
                                                                <th width="50">#</th>
                                                                <th width="180">{{$t('column.title')}}</th>
                                                                <th width="60">{{$t('column.action')}}</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr v-for="(ct, i) in reexportLoadFormState.orders[index].documents"
                                                                :class="[{'table-primary': ct.token === reexportLoadFormState.orders[index].document.token}]">
                                                                <td>{{i + 1}}</td>
                                                                <td>
                                                                    {{ct.title}}
                                                                </td>
                                                                <td>
                                                                    <a @click="handleEditReexportLoadDocumentClick(index, ct.token)"
                                                                       :title="$t('button.title.editItem')"
                                                                       v-b-tooltip.hover>
                                                                        <i class="fe fe-edit"></i>
                                                                    </a>
                                                                    <a :title="$t('button.download')" class=" ml-1"
                                                                       :href="ct.document.download_url"
                                                                       target="_blank" v-b-tooltip.hover>
                                                                        <i class="fa fa-cloud-download"></i>
                                                                    </a>
                                                                    <a-popconfirm title="Are you sure？" @confirm="handleDeleteReexportLoadDocumentClick(index, ct.token)">
                                                                        <i slot="icon" class="fe fe-trash"></i>
                                                                        <a class=" ml-1"
                                                                           :title="$t('button.title.deleteItem')"
                                                                           v-b-tooltip.hover>
                                                                            <i class="fe fe-trash"></i>
                                                                        </a>
                                                                    </a-popconfirm>
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                            <tfoot v-show="reexportLoadFormState.orders[index].documents.length <= 0">
                                                            <tr>
                                                                <th colspan="3">{{$t('title.noDataAvailable')}}</th>
                                                            </tr>
                                                            </tfoot>
                                                        </table><!-- /.table table-bordered -->
                                                    </b-col><!--/b-col-->
                                                </b-row><!--/b-row-->
                                            </div><!-- /.bg-light -->
                                        </b-collapse><!-- /#reexport-load-documents-->
                                    </b-card><!-- /b-card -->
                                </b-col><!--/b-col-->
                            </b-row><!--/.row-->
                            <hr />
                        </b-col><!--/.col-->
                    </b-row><!--/.row-->

                    <div class="drawer-footer">
                        <b-button
                            size="sm"
                            type="submit"
                            variant="primary"
                            :disabled="global.pendingRequests > 0"
                            v-b-tooltip.hover :title="$t('button.title.save')"
                        >
                            <clip-loader style="display: inline" :loading="true" color="#fff"
                                         size="12px" v-if="global.pendingRequests > 0"></clip-loader>
                            <i class="fa fa-save mr-1"></i>
                            {{$t('button.save')}}
                        </b-button>
                        <b-button variant="warning" class="ml-3"
                                  size="sm" @click="handleReexportLoadFormOperationClose()"
                                  v-b-tooltip.hover :title="$t('button.title.cancel')">
                            <i class="fa fa-arrow-left mr-1"></i> {{$t('button.cancel')}}
                        </b-button>
                    </div><!--/.drawer-footer-->
                </form><!--/form-->
            </a-drawer><!--/.drawer-->
        </div>
    </div><!--/div-->
</template>
<script>
    import ListingMixin from '../../../util/ListingMixin'
    import Error from '../../../util/Error'
    import {mapState} from 'vuex'
    import Datepicker from 'vuejs-datepicker'
    import {request} from '../../../util/Request'
    import Treeselect from '@riophae/vue-treeselect'
    import Detail from './detail'
    import SupplierAdditionalService from './SupplierAdditionalService'
    import CreatePriorityMixin from "../CreatePriorityMixin"
    import CreateReexportLoadMixin from "../compound/CreateReexportLoadMixin"
    import QuickLocationForm from "../../locations/QuickLocationForm"
    import QuickSupplierForm from "../../suppliers/QuickSupplierForm"

    const FORM_STATE = {
        visible: false,
        comments: null,
        _method: 'post',
    };

    const FILTER_STATE = {
        visible: false,
        from_date: null,
        to_date: null,
        brands: [],
        models: [],
        dealers: [],
        suppliers: [],
        order_countries: [],
        status: [8, 9],
        status_additional_info: null,
        fromLocations: [],
        toLocations: [],
    };

    const COLUMN_DEFINITION = (self) => [
        {
            label: self.$t('column.orderId'),
            key: 'order_id',
            stickyColumn: true,
            sortable: true
        },
        {
            label: self.$t('column.vin'),
            key: 'vin_number',
            sortable: true,
        },
        {
            label: self.$t('column.loadingPoint'),
            key: 'from_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.unLoadingPoint'),
            key: 'to_location_id',
            sortable: true,
            class: 'w-220'
        },
        {
            label: self.$t('column.market'),
            key: 'order_country',
            sortable: true,
        },
        {
            label: self.$t('column.brand'),
            key: 'brand_id',
            sortable: true,
        },
        {
            label: self.$t('column.model'),
            key: 'model_id',
            sortable: true,
        },
        {
            label: self.$t('column.clientTransportRequest'),
            key: 'client_tr_at',
            sortable: true,
        },
        {
            label: self.$t('column.status'),
            key: 'load_status',
            sortable: true,
        },
        {
            label: self.$t('column.logisticType'),
            key: 'logistic_type',
            sortable: false,
        },
        {
            label: self.$t('column.action'),
            class: 'text-right',
            key: 'action',
            width: 150,
        }
    ];

    export default {
        mixins: [ListingMixin, SupplierAdditionalService, CreatePriorityMixin, CreateReexportLoadMixin],
        components: {
            Treeselect,
            Detail,
            QuickLocationForm,
            QuickSupplierForm
        },
        data() {
            return {
                operationTitle: 'title.orders',
                formFields: {...FORM_STATE},
                filters: {...FILTER_STATE},
                listUrl: 'orders/client',
                columns: COLUMN_DEFINITION(this),
                dropdowns: {
                    countries: [],
                    clients: [],
                    brands: [],
                    models: [],
                    dealers: [],
                    dealersLocations: [],
                    supplierCarriers: [],
                    supplierCompounds: [],
                    locations: [],
                    status: [
                        {id: 1, label: this.$t('title.pending')},
                        {id: 2, label: this.$t('title.addedToLoad')},
                        {id: 3, label: this.$t('title.assignedToLoad')},
                        {id: 4, label: this.$t('title.loaded')},
                        {id: 5, label: this.$t('title.inTransit')},
                        {id: 6, label: this.$t('title.unloadRequested')},
                        {id: 7, label: this.$t('title.unloaded')},
                        {id: 8, label: this.$t('title.stocked')},
                        {id: 9, label: this.$t('title.hold')},
                        {id: 10, label: this.$t('title.transportOrder')},
                        {id: 11, label: this.$t('title.announced')},
                        {id: 12, label: this.$t('title.rejected')},
                        {id: 13, label: this.$t('title.confirmed')},
                        {id: 14, label: this.$t('title.pickUp')},
                        {id: 15, label: this.$t('title.stockExitInTransit')},
                        {id: 16, label: this.$t('title.deliveryRequested')},
                        {id: 17, label: this.$t('title.delivered')},
                    ],
                    statusAdditionalInfo: [
                        {id: -1, label: this.$t('title.showOnlyStockOrders')},
                        {id: 1, label: this.$t('title.requested')},
                        {id: 2, label: this.$t('title.rejected')},
                        {id: 3, label: this.$t('title.confirmed')},
                        {id: 4, label: this.$t('title.clientAffected')},
                        {id: 5, label: this.$t('title.deliveryRequest')},
                        {id: 6, label: this.$t('title.readyToBeLoaded')},
                        {id: 7, label: this.$t('title.serviceOrders')},
                        {id: 8, label: this.$t('title.damaged')},
                        {id: 9, label: this.$t('title.serviceOrders') + ' | ' + this.$t('title.damaged')},
                    ],
                    priorities: [
                        {id: 1, label: this.$t('title.high')},
                        {id: 2, label: this.$t('title.medium')},
                        {id: 3, label: this.$t('title.low')}
                    ],
                    suppliersAdditionalServices: [],
                    transportTypes: [
                        {id: 'cc', label: 'CC'},
                        {id: 'outbound', label: 'Outbound'},
                    ],
                },
                show: true,
                priorityFormVisible: false,

                reexportLoadFormVisible: false,
                groupedReexportOrders: [],
                selectedReexportOrderLength: 0,
            }
        },
        mounted() {
            this.getDealers();
            this.getBrands();
            this.getModels();
            this.getLocations();
            this.getCountries();
            this.getSupplierCarriers();
            this.getSupplierCompounds();
            this.$title = this.$t('title.orders')
        },
        methods: {
            toggleDetails(row) {
                this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
            },
            setOperation(operation = 'add', operationToken = null) {
                this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
                this.$router.replace({
                    query: Object.assign({},
                        this.$route.query,
                        {
                            ...this.listQueryParams,
                            operation: operation,
                            oToken: operationToken,
                        },
                    ),
                }).then(() => {
                }).catch(() => {
                })
            },
            handleRowClick(item, rowIndex, event) {
                this.handleLoadRowClick(item, rowIndex, event)
                this.handleReexportRowSelection(item, rowIndex, event)
            },
            async handleSubmit() {
                this.formErrors = new Error({})

                try {
                    const response = await request({
                        url: 'orders/client/transport/request',
                        method: 'post',
                        data: this.formFields,
                    })

                    this.itemUpdated()
                    this.formFields = {...FORM_STATE}
                    this.loadList()
                } catch (error) {
                    if (error.request && error.request.status && error.request.status === 422) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors)
                        return false
                    }

                    this.handleServerError(error)
                }
            },
            async handleEditClick(item) {
                this.formFields.id = item.id
                this.formFields.comments = null
                this.formFields.visible = true
            },
            async getBrands() {
                try {
                    const response = await request({
                        url: '/dropdowns/brands',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.brands = data

                } catch (e) {
                    this.dropdowns.brands = []
                }
            },
            async getModels() {
                try {
                    const response = await request({
                        url: '/dropdowns/models',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.models = data

                } catch (e) {
                    this.dropdowns.models = []
                }
            },
            async getDealers() {
                try {
                    const response = await request({
                        url: '/dropdowns/dealers',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.dealers = data
                } catch (e) {
                    this.dropdowns.dealers = []
                    this.dropdowns.dealersLocations = []
                }
            },
            async getSupplierCompounds() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/compound',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCompounds = data

                } catch (e) {
                    this.dropdowns.supplierCompounds = []
                }
            },
            async getLocations() {
                try {
                    const response = await request({
                        url: '/dropdowns/locations',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.locations = data

                } catch (e) {
                    this.dropdowns.locations = []
                }
            },
            async getCountries() {
                try {
                    const response = await request({
                        url: '/dropdowns/countries',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.countries = data

                } catch (e) {
                    this.dropdowns.countries = []
                }
            },
            async getSupplierCarriers() {
                try {
                    const response = await request({
                        url: '/dropdowns/suppliers/carrier',
                        method: "post"
                    })

                    const {data} = response
                    this.dropdowns.supplierCarriers = data

                } catch (e) {
                    this.dropdowns.supplierCarriers = []
                }
            },
            async handleResetTrClick({id}) {
                try {
                    const response = await request({
                        url: '/orders/client/reset/transport/request',
                        method: 'post',
                        data: {
                            id: id
                        },
                    })

                    this.itemUpdated()
                    this.loadList()
                } catch (error) {
                    this.handleServerError(error)
                }
            },
            hasListAccess() {
                return this.$global.hasPermission('ordersownview')
            },
            refreshList() {
                this.loadList()
            },
            getExtraParams() {
                return {
                    filters: {
                        ...this.filters,
                        from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                        to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    },
                }
            },
            handleResetFilterClick() {
                this.filters = {...FILTER_STATE}
                this.isFilterApplied = 'reset'
                this.handleResetClick()
                this.loadList(this.listQueryParams)
            },
            afterCloseOperation() {
                this.formFields = {...FORM_STATE}
            },
        },
        computed: {
            ...mapState([
                'global',
                'settings'
            ]),
        },
    }
</script>
<style lang="scss">
    .w-80{
        width: 80px;
    }

    .w-220{
        width: 220px;
    }

    .in-center{
        position: relative;
        top: 1px;
        margin-right: 8px;
    }

    @media screen and (max-width: 1379px) {
        .ant-drawer-content-wrapper {
            width: 98% !important;
        }
    }

    .indicator-added-inbound-load{
        bottom: -4px;
        left: 0;
    }

    .indicator-added-outbound-load{
        bottom: -4px;
        left: 15px;
    }
    .indicator-added-priority {
        bottom: -13px;
        left: 0;
    }
</style>
